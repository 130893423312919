import React from 'react'
import { connect } from 'react-redux'
import { isValid, parse, format } from 'date-fns'
import { Button, Form, Modal } from 'react-bootstrap'
import hash from 'object-hash'
import DatePicker from 'react-datepicker'
import localeIt from 'date-fns/locale/it'
import 'react-datepicker/dist/react-datepicker.css'

import validateFields from 'lib/validators/trainings'
import { updateErrors } from 'store/trainings'
import { getElements as getTopics, createElement as createTopic } from 'store/topics'

const initialState = {
  id: null,
  title: '',
  start: '',
  end: '',
  trainer_id: null,
  resourceId: '',
  partecipants: 1,
  gender: '',
  level: '',
}

const mstp = state => {
  let { current_element: element, errors } = state.trainings
  let { elements: places } = state.places
  let { trainers } = state.users
  let { elements: topics } = state.topics

  if (places.length === 0) places = [{ id: '00', name: 'Nessun luogo esistente' }]
  return {
    places,
    trainers,
    element,
    errors,
    topics,
  }
}

class PlannerForm extends React.Component {
  static defaultProps = {
    closeForm: () => {},
    onSave: () => {},
    errors: {},
    element: initialState,
    topics: [],
    trainers: [],
    places: [],
  }
  state = {
    element: initialState,
    element_hash: '',
    validated: false,
    topic_name: '',
    show_new_topic: false,
  }

  setElement = () => {
    let element = {
      ...this.state.element,
      ...this.props.element,
    }
    let element_hash = hash(element)
    this.setState({ element, element_hash })
    this.initialHash = hash(element)
  }
  async componentDidMount() {
    this.setElement()
    await this.props.dispatch(getTopics({ offset: -1, limit: '', sortData: {}, filter: '' }))
  }

  componentDidUpdate = prevProps => {
    if (prevProps.element.id !== this.props.element.id) {
      this.setElement()
    }
  }

  updateHash = () => {
    let { element } = this.state
    this.setState({ element_hash: hash(element) })
  }

  handleChange = ({ target: { name, value } }) => {
    let element = { ...this.state.element }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    element[name] = ['partecipants'].includes(name) ? parseInt(value) : value
    this.setState({ element }, () => this.updateHash())
  }
  handleSelectChange = name => e => {
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ element: { ...this.state.element, [name]: e.target.value } }, () => this.updateHash())
  }
  handleDateChange = name => date => {
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    let newDate = date !== null && isValid(date) ? format(date, 'yyyy-MM-dd HH:mm:ss') : ''
    this.setState({ element: { ...this.state.element, [name]: newDate } }, () => this.updateHash())
  }

  handleConfirm = async () => {
    let errors = validateFields({ ...this.state.element })
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      let saved = await this.props.onSave(this.state.element)
      if (saved) this.props.onCancelNew()
    }
    this.setState({ validated: true })
  }

  openNewTopic = () => this.setState({ show_new_topic: true })
  closeNewTopic = () => this.setState({ show_new_topic: false })
  handleChangeTopic = ({ target: { value } }) => {
    this.setState({ topic_name: value })
  }
  createNewTopic = async () => {
    let topic = { id: null, name: this.state.topic_name }
    await this.props.dispatch(createTopic(topic))
    await this.props.dispatch(getTopics({ offset: -1, limit: '', sortData: {}, filter: '' }))
    this.setState({
      show_new_topic: false,
      element: { ...this.state.element, title: this.state.topic_name },
      topic_name: '',
    })
  }

  render() {
    let { show_modal_new, trainers, places, errors, topics } = this.props
    let { validated, element, show_new_topic, topic_name } = this.state
    let { id, title, start, end, trainer_id, resourceId, partecipants, gender, level } = element

    let parsedStartAt = parse(start ?? '', 'yyyy-MM-dd HH:mm:ss', new Date())
    parsedStartAt = isValid(parsedStartAt) ? parsedStartAt : null
    let parsedEndAt = parse(end ?? '', 'yyyy-MM-dd HH:mm:ss', new Date())
    parsedEndAt = isValid(parsedEndAt) ? parsedEndAt : null

    return (
      <>
        <Modal backdrop="static" centered size="lg" show={show_modal_new} onHide={this.props.onCancelNew}>
          <Modal.Header closeButton style={{ opacity: show_new_topic ? 0.3 : 1 }}>
            <Modal.Title>{id ? 'Modifica' : 'Aggiungi'} sessione</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ opacity: show_new_topic ? 0.3 : 1 }}>
            <div className="flex-fill overflow-auto container">
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>Fondamentale trattato:</Form.Label>
                    <span> *</span>
                    <div className="d-flex">
                      <Form.Select
                        value={title}
                        onChange={this.handleSelectChange('title')}
                        isInvalid={validated && 'title' in errors}>
                        <option value=""></option>
                        {topics.map(el => {
                          return (
                            <option key={`rol-opt-${el.name}`} value={el.name}>
                              {el.name}
                            </option>
                          )
                        })}
                      </Form.Select>
                      <Button className="ms-2" onClick={this.openNewTopic}>
                        +
                      </Button>
                    </div>
                    <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>Luogo/Campo:</Form.Label>
                    <span> *</span>
                    <Form.Select
                      value={resourceId}
                      onChange={this.handleSelectChange('resourceId')}
                      isInvalid={validated && 'resourceId' in errors}>
                      <option value="">Nessuna selezione</option>
                      {places.map(el => {
                        return (
                          <option key={`rol-opt-${el.id}`} value={el.id}>
                            {el.name}
                          </option>
                        )
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.resourceId}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>Inizio ciclo:</Form.Label>
                    <span> *</span>
                    <div>
                      <DatePicker
                        style={{ lineHeight: 1.7 }}
                        className={`form-control ${validated && 'start' in errors ? 'border border-danger' : ''}`}
                        selected={parsedStartAt}
                        dateFormat="yyyy-MM-dd HH:mm"
                        timeFormat="HH:mm"
                        locale={localeIt}
                        maxDate={parsedEndAt}
                        onChange={this.handleDateChange('start')}
                        showTimeSelect
                        isClearable
                        showYearDropdown
                        yearDropdownItemNumber={20}
                        scrollableYearDropdown
                      />
                      {'start' in errors && (
                        <div className="text-danger" style={{ fontSize: '0.875em' }}>
                          {errors.start}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>Fine ciclo:</Form.Label>
                    <span> *</span>
                    <div>
                      <DatePicker
                        style={{ lineHeight: 1.7 }}
                        className={`form-control ${validated && 'end' in errors ? 'border border-danger' : ''}`}
                        selected={parsedEndAt}
                        dateFormat="yyyy-MM-dd HH:mm"
                        timeFormat="HH:mm"
                        locale={localeIt}
                        minDate={parsedStartAt}
                        onChange={this.handleDateChange('end')}
                        showTimeSelect
                        isClearable
                        showYearDropdown
                        yearDropdownItemNumber={20}
                        scrollableYearDropdown
                      />
                      {'end' in errors && (
                        <div className="text-danger" style={{ fontSize: '0.875em' }}>
                          {errors.end}
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>Allenatore:</Form.Label>
                    <Form.Select
                      value={trainer_id}
                      onChange={this.handleSelectChange('trainer_id')}
                      isInvalid={validated && 'trainer_id' in errors}>
                      <option value="1">Nessuna selezione</option>
                      {trainers.map(el => {
                        return (
                          <option key={`rol-opt-${el.id}`} value={el.id}>
                            {el.first_name} {el.last_name}
                          </option>
                        )
                      })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.trainer_id}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>Numero partecipanti:</Form.Label>
                    <Form.Control
                      name="partecipants"
                      value={partecipants}
                      type="numeric"
                      onChange={this.handleChange}
                      isInvalid={validated && 'partecipants' in errors}
                    />
                    <Form.Control.Feedback type="invalid">{errors.partecipants}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>Genere:</Form.Label>
                    <Form.Select
                      value={gender}
                      onChange={this.handleSelectChange('gender')}
                      isInvalid={validated && 'gender' in errors}>
                      <option value="">Nessuna selezione</option>
                      <option key={`rol-opt-maschile`} value={'Maschile'}>
                        Maschile
                      </option>
                      <option key={`rol-opt-femminile`} value={'Femminile'}>
                        Femminile
                      </option>
                      <option key={`rol-opt-altro`} value={'Altro'}>
                        Altro
                      </option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.gender}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-3 mb-3">
                    <Form.Label>Livello:</Form.Label>
                    <Form.Select
                      value={level}
                      onChange={this.handleSelectChange('level')}
                      isInvalid={validated && 'level' in errors}>
                      <option value="">Nessuna selezione</option>
                      <option key={`rol-opt-base`} value={'Base'}>
                        Base
                      </option>
                      <option key={`rol-opt-intermedio`} value={'Intermedio'}>
                        Intermedio
                      </option>
                      <option key={`rol-opt-avanzato`} value={'Avanzato'}>
                        Avanzato
                      </option>
                      <option key={`rol-opt-pro`} value={'Pro'}>
                        Pro
                      </option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.resourceId}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.onCancelNew} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.handleConfirm} variant="primary">
              Salva
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal backdrop="static" centered show={show_new_topic} onHide={this.closeNewTopic}>
          <Modal.Header closeButton>
            <Modal.Title>Nuovo fondamentale</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mt-3 mb-3">
              <Form.Label>Fondamentale</Form.Label>
              <span> *</span>
              <Form.Control name="topic_name" value={topic_name} onChange={this.handleChangeTopic} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.closeNewTopic} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.createNewTopic} variant="primary" disabled={!this.state.topic_name}>
              Aggiungi
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default connect(mstp)(PlannerForm)

import i18next from 'i18next'

const { t } = i18next

const validateFields = data => {
  let requiredFields = ['title', 'start', 'end', 'resourceId']

  let errors = {}

  // check required fields
  for (const field of requiredFields) {
    if (!data[field]) errors[field] = t('user.user_form.errors.required_field', 'Campo richiesto')
  }

  if (data['start'] >= data['end']) {
    errors['start'] = 'Intervallo errato'
    errors['end'] = 'Intervallo errato'
  }

  return errors
}

export default validateFields

export const levels = {
  'Base-Maschile': { bgColor: 'rgb(128, 197, 246)' },
  'Intermedio-Maschile': { bgColor: '#4897ff' },
  'Avanzato-Maschile': { bgColor: '#2264ba' },
  'Pro-Maschile': { bgColor: '#133c70' },
  'Base-Femminile': { bgColor: '#f4c6f5' },
  'Intermedio-Femminile': { bgColor: '#f276f5' },
  'Avanzato-Femminile': { bgColor: '#e802a4' },
  'Pro-Femminile': { bgColor: '#99026c' },
}

export const statuses = {
  Prenotata: { bgColor: 'rgb(128, 197, 246)' },
  'In attesa': { bgColor: 'orange' },
  Cancellata: { bgColor: 'red' },
}

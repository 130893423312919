import { configureStore } from '@reduxjs/toolkit'

import userinfoReducer from './userinfo'
import usersReducer from './users'
import ciclesReducer from './cicles'
import placesReducer from './places'
import causesReducer from './causes'
import topicsReducer from './topics'
import paymentsReducer from './payments'
import trainingsReducer from './trainings'
import reservationsReducer from './reservations'
import subscriptionsReducer from './subscriptions'
import notificationsReducer from './notifications'

const addGlobalResetEnhancer = createStore => (reducer, initialState, enhancer) => {
  const resettableReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
      return reducer(undefined, action)
    } else {
      return reducer(state, action)
    }
  }
  return createStore(resettableReducer, initialState, enhancer)
}

export function makeStore() {
  return configureStore({
    reducer: {
      userinfo: userinfoReducer,
      users: usersReducer,
      cicles: ciclesReducer,
      places: placesReducer,
      causes: causesReducer,
      topics: topicsReducer,
      trainings: trainingsReducer,
      payments: paymentsReducer,
      reservations: reservationsReducer,
      subscriptions: subscriptionsReducer,
      notifications: notificationsReducer,
    },
    enhancers: [addGlobalResetEnhancer],
  })
}

const store = makeStore()
export default store

export const resetStore = () => store.dispatch({ type: 'RESET_STORE' })

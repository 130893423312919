import { Component } from 'react'
import { connect } from 'react-redux'
import * as dayjsLocale from 'dayjs/locale/it'
import * as antdLocale from 'antd/locale/it_IT'
import { Scheduler, SchedulerData, ViewType } from 'react-big-schedule'
import { Modal, Button } from 'react-bootstrap'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import 'react-big-schedule/dist/css/style.css'

import { levels } from 'utils/constants'
import { getElements as getPlaces } from 'store/places'
import {
  getElements as getTrainings,
  createElement,
  updateElement,
  deleteElement,
  setCurrentElement,
  resetCurrentElement,
  updateErrors,
  cloneDay,
} from 'store/trainings'
import { getTrainers } from 'store/users'
import ElementForm from './form'
import CloneForm from './clone'

const padStart = str => {
  return str < 10 ? '0' + str : str
}

const mstp = state => {
  let { elements, total, show_form, current_element, errors } = state.trainings
  let { elements: places } = state.places
  let { trainers } = state.users

  let sortedElements = [...elements]
  sortedElements.sort((a, b) => (a.start < b.start ? -1 : 1))
  sortedElements = sortedElements.map(el => ({
    ...el,
    topic: el.title,
    title: el.level,
    bgColor: levels[`${el.level}-${el.gender}`]?.bgColor ?? '',
  }))

  if (places.length === 0) places = [{ id: '00', name: 'Nessun luogo esistente' }]
  return {
    elements: sortedElements,
    places,
    trainers,
    total,
    show_form,
    current_element,
    errors,
  }
}

class Planner extends Component {
  constructor(props) {
    super(props)

    let today = new Date().toLocaleDateString('sv')
    let schedulerData = new SchedulerData(today, ViewType.Day, false, false, {
      besidesWidth: window.innerWidth <= 1600 ? 100 : 370,
      eventItemPopoverTrigger: 'click',
      schedulerContentHeight: '100%',
      startResizable: true,
      endResizable: true,
      nonWorkingTimeBodyBgColor: 'transparent',
      nonWorkingTimeHeadBgColor: 'transparent',
      nonWorkingTimeHeadColor: 'black',
      dayStartFrom: 7,
      dayStopTo: 23,
      dayCellWidth: 40,
      eventItemLineHeight: 40,
      resourceName: 'Luogo/Campo',
      views: [
        { viewName: 'Day', viewType: ViewType.Day, showAgenda: false, isEventPerspective: false },
        { viewName: 'Week', viewType: ViewType.Week, showAgenda: false, isEventPerspective: false },
      ],
    })

    schedulerData.setSchedulerLocale(dayjsLocale)
    schedulerData.setCalendarPopoverLocale(antdLocale)
    schedulerData.setResources(props.places)
    schedulerData.setEvents([])
    this.state = {
      viewModel: schedulerData,
      show_modal_delete: false,
      show_modal_new: false,
      show_modal_clone: false,
      element_to_delete: {},
      element: {},
    }
  }

  async componentDidMount() {
    this.getData()
    await this.props.dispatch(getTrainers())
  }

  getData = async (start = '', end = '') => {
    await this.props.dispatch(getPlaces({ offset: 0, limit: 0, sortData: {}, filter: '' }))
    await this.props.dispatch(getTrainings({ offset: 0, limit: 0, sortData: {}, start, end }))
    let schedulerData = this.state.viewModel
    schedulerData.setResources(this.props.places)
    schedulerData.setEvents(this.props.elements)
    this.setState({ viewModel: schedulerData })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.places !== this.props.places) {
      let schedulerData = this.state.viewModel
      schedulerData.setResources(this.props.places)
    }
    if (prevProps.elements !== this.props.elements) {
      let schedulerData = this.state.viewModel
      schedulerData.setEvents(this.props.elements)
    }
  }

  prevClick = schedulerData => {
    schedulerData.prev()
    let selectedDate = schedulerData.endDate.$d.toLocaleDateString('sv')
    this.getData(selectedDate)
  }

  nextClick = schedulerData => {
    schedulerData.next()
    let selectedDate = schedulerData.endDate.$d.toLocaleDateString('sv')
    this.getData(selectedDate)
  }

  onSelectDate = (schedulerData, date) => {
    schedulerData.setDate(date)
    this.getData(date)
  }

  editInterval = event => async () => {
    await this.props.dispatch(setCurrentElement(event))
    this.setState({ show_modal_new: true })
  }

  deleteInterval = event => () => {
    this.setState({ show_modal_delete: true, element_to_delete: event })
  }

  newEvent = async (schedulerData, slotId, slotName, start, end, type, item) => {
    let newEvent = {
      title: 'Nuovo slot',
      start: start,
      end: end,
      trainer_id: 1,
      resourceId: slotId,
    }
    await this.props.dispatch(createElement(newEvent))
    let selectedDate = schedulerData.endDate.$d.toLocaleDateString('sv')
    this.getData(selectedDate)
  }

  updateEventStart = async (schedulerData, event, newStart) => {
    let newEvent = { ...event }
    newEvent.start = newStart
    await this.props.dispatch(updateElement(newEvent))
    let selectedStartDate = schedulerData.startDate.$d.toLocaleDateString('sv')
    let selectedEndDate = schedulerData.endDate.$d.toLocaleDateString('sv')
    this.getData(selectedStartDate, selectedEndDate)
  }

  updateEventEnd = async (schedulerData, event, newEnd) => {
    let newEvent = { ...event }
    newEvent.end = newEnd
    await this.props.dispatch(updateElement(newEvent))
    let selectedStartDate = schedulerData.startDate.$d.toLocaleDateString('sv')
    let selectedEndDate = schedulerData.endDate.$d.toLocaleDateString('sv')
    this.getData(selectedStartDate, selectedEndDate)
  }

  moveEvent = async (schedulerData, event, slotId, slotName, start, end) => {
    let newEvent = { ...event }
    newEvent = { ...newEvent, start, end, resourceId: slotId }
    await this.props.dispatch(updateElement(newEvent))
    let selectedStartDate = schedulerData.startDate.$d.toLocaleDateString('sv')
    let selectedEndDate = schedulerData.endDate.$d.toLocaleDateString('sv')
    this.getData(selectedStartDate, selectedEndDate)
  }

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId)
    this.setState({
      viewModel: schedulerData,
    })
  }

  onCancelDelete = () => {
    this.setState({ show_modal_delete: false })
  }
  onConfirmDelete = async () => {
    await this.props.dispatch(deleteElement(this.state.element_to_delete.id))
    this.setState({ show_modal_delete: false })
    let selectedStartDate = this.state.viewModel.startDate.$d.toLocaleDateString('sv')
    let selectedEndDate = this.state.viewModel.endDate.$d.toLocaleDateString('sv')
    this.getData(selectedStartDate, selectedEndDate)
  }
  onCancelNew = () => {
    this.setState({ show_modal_new: false })
    this.props.dispatch(resetCurrentElement())
    this.props.dispatch(updateErrors({}))
  }
  onAddNew = () => {
    this.setState({ show_modal_new: true })
    this.props.dispatch(resetCurrentElement())
  }
  saveElement = async element => {
    try {
      if (element.id !== null) {
        await this.props.dispatch(updateElement(element))
      } else {
        await this.props.dispatch(createElement(element))
      }
      let selectedStartDate = this.state.viewModel.startDate.$d.toLocaleDateString('sv')
      let selectedEndDate = this.state.viewModel.endDate.$d.toLocaleDateString('sv')
      this.getData(selectedStartDate, selectedEndDate)
      this.props.dispatch(resetCurrentElement())
      return true
    } catch (err) {
      return false
    }
  }

  cloneDay = async ({ start, end }) => {
    try {
      await this.props.dispatch(cloneDay({ start, end }))
      let { viewModel } = this.state
      viewModel.setDate(end)
      this.getData(end)
      return true
    } catch (err) {
      return false
    }
  }
  onOpenClone = () => this.setState({ show_modal_clone: true })
  onCancelClone = () => this.setState({ show_modal_clone: false })

  onViewChange = (schedulerData, view) => {
    if (schedulerData.viewType === 0) schedulerData.config.creatable = false
    else schedulerData.config.creatable = true
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective)
    let date_start = schedulerData.startDate.$d.toLocaleDateString('sv')
    let date_end = schedulerData.endDate.$d.toLocaleDateString('sv')
    this.getData(date_start, date_end)
  }

  customPopoverRender = (schedulerData, eventItem, title, start, end, statusColor) => {
    let date = new Date(eventItem.start.split(' ')[0])
    let day = date.getDate()
    let month = date.toLocaleString('default', { month: 'short' })

    return (
      <div style={{ width: 400 }}>
        <div class="d-flex ant-row align-items-center">
          <div class="ant-col ant-col-2">
            <div class="status-dot" style={{ backgroundColor: statusColor }}></div>
          </div>
          <div class="ant-col ant-col-22 overflow-text ms-3">
            <span class="header2-text" title="Nuovo slot">
              {eventItem.topic}
            </span>
          </div>
        </div>
        <div type="flex" class="ant-row ant-row-middle">
          <div class="ant-col ant-col-2">
            <div></div>
          </div>
          <div class="ant-col ant-col-22">
            <span class="header1-text">
              {padStart(start.$H)}:{padStart(start.$m)}
            </span>
            <span class="help-text" style={{ marginLeft: 8 }}>
              {day} {month}
            </span>
            <span class="header2-text" style={{ marginLeft: 8 }}>
              -
            </span>
            <span class="header1-text" style={{ marginLeft: 8 }}>
              {padStart(end.$H)}:{padStart(end.$m)}
            </span>
            <span class="help-text" style={{ marginLeft: 8 }}>
              {day} {month}
            </span>
          </div>
        </div>
        <div className="d-flex flex-row">
          <div class="p-2 border mt-2 mb-2 col">
            <div>
              Allenatore: <div className="text-success fw-bold">{eventItem.trainer}</div>
            </div>
            <div>
              Luogo/Campo: <div className="text-success fw-bold">{eventItem.place_name}</div>
            </div>
            <div>
              Partecipanti (max): <div className="text-success fw-bold">{eventItem.partecipants}</div>
            </div>
          </div>
          <div class="p-2 border mt-2 mb-2 col">
            <div>
              Livello: <div className="text-success fw-bold">{eventItem.level ?? '-'}</div>
            </div>
            <div>
              Genere: <div className="text-success fw-bold">{eventItem.gender ?? '-'}</div>
            </div>
          </div>
        </div>
        <div class="ant-col ant-col-22">
          <button
            onClick={this.editInterval(eventItem)}
            class="header2-text txt-btn-dis"
            type="button"
            style={{ color: 'rgb(16, 142, 233)', cursor: 'pointer', marginLeft: 0 }}>
            Modifica
          </button>
          <button
            onClick={this.deleteInterval(eventItem)}
            class="header2-text txt-btn-dis"
            type="button"
            style={{ color: 'rgb(16, 142, 233)', cursor: 'pointer', marginLeft: 16 }}>
            Elimina
          </button>
        </div>
      </div>
    )
  }

  render() {
    let { viewModel } = this.state

    return (
      <>
        <DndProvider backend={HTML5Backend}>
          <Scheduler
            schedulerData={viewModel}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onViewChange={this.onViewChange}
            onSelectDate={this.onSelectDate}
            updateEventStart={this.updateEventStart}
            updateEventEnd={this.updateEventEnd}
            moveEvent={this.moveEvent}
            newEvent={this.newEvent}
            eventItemPopoverTemplateResolver={this.customPopoverRender}
            toggleExpandFunc={this.toggleExpandFunc}
          />
        </DndProvider>
        <div className="d-flex ps-4">
          <Button onClick={this.onAddNew} variant="primary">
            Aggiungi nuovo
          </Button>
          <Button onClick={this.onOpenClone} variant="primary" className="ms-2">
            Clona giorno
          </Button>
        </div>
        <Modal backdrop="static" centered show={this.state.show_modal_delete} onHide={this.onCancelDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Elimina elemento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Per procedere ad eliminiare <b>{this.state.element_to_delete.title}</b> click su Elimina
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancelDelete} variant="secondary">
              Annulla
            </Button>
            <Button onClick={this.onConfirmDelete} variant="danger">
              Elimina
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.show_modal_new && (
          <ElementForm
            onCancelNew={this.onCancelNew}
            show_modal_new={this.state.show_modal_new}
            onSave={this.saveElement}
          />
        )}
        {this.state.show_modal_clone && (
          <CloneForm
            onCancel={this.onCancelClone}
            show_modal_clone={this.state.show_modal_clone}
            onConfirm={this.cloneDay}
          />
        )}
      </>
    )
  }
}

export default connect(mstp)(Planner)

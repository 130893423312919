import * as React from 'react'
import { connect } from 'react-redux'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import { LuCalendarCheck, LuCalendarClock, LuUserSquare } from 'react-icons/lu'
import { FaAddressCard, FaBook, FaChartBar, FaFileInvoiceDollar, FaLayerGroup, FaRunning } from 'react-icons/fa'
import { FaClipboardQuestion, FaMoneyCheckDollar, FaSackDollar } from 'react-icons/fa6'
import { MdOutlinePlace } from 'react-icons/md'
import { Link } from 'react-router-dom'
import logoIcon from '../../assets/images/logo-icon.png'
import logoText from '../../assets/images/logo-text.png'
import styles from './sidebar.module.css'
import withRouter from 'utils/withRouter'

const mstp = state => {
  return {
    user: state.userinfo.user,
  }
}
class CustomSidebar extends React.Component {
  state = {
    sidebar_collapsed: window.innerWidth < 680,
    screen_width: window.innerWidth,
  }

  onToggle = () => {
    this.setState({ sidebar_collapsed: !this.state.sidebar_collapsed })
  }

  render() {
    let { user } = this.props
    let { screen_width, sidebar_collapsed } = this.state
    let { pathname } = this.props.router.location

    let menuItemStyles = {
      icon: () => ({ marginRight: '8px' }),
      subMenuContent: () => ({ width: 'unset' }),
      button: ({ active }) => {
        return {
          userSelect: 'none',
          height: '53px',
          paddingLeft: '0.9rem',
          paddingRight: '1rem',
          paddingBottom: '0.3rem',
          backgroundColor: active ? '#dde4ea' : undefined,
          borderTop: '1px solid #dde4ea',
        }
      },
    }

    return (
      <Sidebar
        className={styles.aside}
        width={screen_width < 680 ? 'calc(100% - var(--tabbar-width) - var(--tabbar-offset))' : '20rem'}
        backgroundColor="#eeeeee"
        collapsed={sidebar_collapsed}
        collapsedWidth="4rem">
        <div className={styles.menuItemContainer}>
          <div className={styles.menuItemToggle} onClick={this.onToggle}>
            <img alt="logo" width="45" height="40" src={logoIcon} className="d-inline-block align-top" />
          </div>
          <div className="ms-2">
            <Link to="/dashboard">
              <img alt="logo" width="140" height="40" src={logoText} className="d-inline-block align-top" />
            </Link>
          </div>
        </div>
        <Menu menuItemStyles={menuItemStyles}>
          <MenuItem
            key={'m-users'}
            active={pathname.includes('/users')}
            onClick={() => {}}
            component={<Link to="/users" />}
            icon={<LuUserSquare size={24} />}>
            <div>Lista utenti</div>
          </MenuItem>
          <SubMenu
            label={'Abbonamenti'}
            defaultOpen={true}
            active={pathname.includes('/cicles') || pathname.includes('/subscriptions')}
            icon={<FaAddressCard size={24} />}>
            <MenuItem
              className="ps-2"
              key={'cicle-list'}
              active={pathname.includes('/cicles/list')}
              onClick={() => {}}
              component={<Link to="/cicles/list" />}
              icon={<FaLayerGroup size={24} />}>
              <div>Tipologia abbonamenti</div>
            </MenuItem>
            <MenuItem
              className="ps-2"
              key={'subscriptions-list'}
              active={pathname.includes('/subscriptions/list')}
              onClick={() => {}}
              component={<Link to="/subscriptions/list" />}
              icon={<FaFileInvoiceDollar size={24} />}>
              <div>Storico sottoscrizioni</div>
            </MenuItem>
          </SubMenu>
          <SubMenu
            label={'Allenamenti'}
            defaultOpen={true}
            active={pathname.includes('/training') || pathname.includes('/places')}
            icon={<FaRunning size={24} />}>
            <MenuItem
              className="ps-2"
              key={'places-list'}
              active={pathname.includes('/places/list')}
              onClick={() => {}}
              component={<Link to="/places/list" />}
              icon={<MdOutlinePlace size={24} />}>
              <div>Luoghi/Campi</div>
            </MenuItem>
            <MenuItem
              className="ps-2"
              key={'topics-list'}
              active={pathname.includes('/topics/list')}
              onClick={() => {}}
              component={<Link to="/topics/list" />}
              icon={<FaBook size={24} />}>
              <div>Fondamentali</div>
            </MenuItem>
            <MenuItem
              className="ps-2"
              key={'trainings-list'}
              active={pathname.includes('/trainings/list')}
              onClick={() => {}}
              component={<Link to="/trainings/list" />}
              icon={<LuCalendarClock size={24} />}>
              <div>Allenamenti schedulati</div>
            </MenuItem>
            <MenuItem
              className="ps-2"
              key={'reservetions-list'}
              active={pathname.includes('/reservetions/list')}
              onClick={() => {}}
              component={<Link to="/reservetions/list" />}
              icon={<LuCalendarCheck size={24} />}>
              <div>Prenotazioni</div>
            </MenuItem>
          </SubMenu>
          <SubMenu
            label={'Rendicontazione'}
            defaultOpen={true}
            active={pathname.includes('/causes') || pathname.includes('/payments') || pathname.includes('/reports')}
            icon={<FaSackDollar size={24} />}>
            <MenuItem
              className="ps-2"
              key={'payments-list'}
              active={pathname.includes('/payments/list')}
              onClick={() => {}}
              component={<Link to="/payments/list" />}
              icon={<FaMoneyCheckDollar size={24} />}>
              <div>Incassi/Spese</div>
            </MenuItem>
            <MenuItem
              className="ps-2"
              key={'reports-list'}
              active={pathname.includes('/reports/list')}
              onClick={() => {}}
              component={<Link to="/reports/list" />}
              icon={<FaChartBar size={24} />}>
              <div>Reports</div>
            </MenuItem>
            <MenuItem
              className="ps-2"
              key={'causes-list'}
              active={pathname.includes('/causes/list')}
              onClick={() => {}}
              component={<Link to="/causes/list" />}
              icon={<FaClipboardQuestion size={24} />}>
              <div>Causali</div>
            </MenuItem>
          </SubMenu>
        </Menu>
      </Sidebar>
    )
  }
}

export default connect(mstp)(withRouter(CustomSidebar))

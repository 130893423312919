import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../axios'
import config from '../config'
import { addSuccessNotification, addErrorNotification } from './notifications'

const initialState = {
  elements: [],
  total: 0,
  loading: false,
  show_disabled: false,
  show_form: false,
  current_element: {},
  errors: {},
}

export const getElements = createAsyncThunk('topics/get_topics', async (params, thunkApi) => {
  try {
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let state = thunkApi.getState().topics
    let url = `${config.SERVER_API_URL}/topics?`
    if (filter) url = url + `&q=${encodeURIComponent(filter)}`
    if (state.show_disabled) url = url + `&disabled=true`
    if (limit) url = url + `&_end=${encodeURIComponent(limit)}`
    if (offset) url = url + `&_start=${encodeURIComponent(offset)}`
    if (sort_direction) url = url + `&_order=${encodeURIComponent(sort_direction)}`
    if (sort_column) url = url + `&_sort=${encodeURIComponent(sort_column)}`

    let response = await axios({
      url,
      method: 'GET',
    })
    let total = parseInt(response.headers['x-total-count'])
    return { elements: response.data, total }
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})
export const createElement = createAsyncThunk('topics/create_topic', async (element, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/topics`,
      method: 'POST',
      data: element,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { element: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification('Impossibile effettuare il salvataggio!'))
    return thunkApi.rejectWithValue({})
  }
})

export const updateElement = createAsyncThunk('topics/update_topic', async (element, thunkApi) => {
  try {
    if (!element.id) throw Error('Impossibile salvare!')
    let response = await axios({
      url: `${config.SERVER_API_URL}/topics/${element.id}`,
      method: 'PUT',
      data: element,
    })
    thunkApi.dispatch(addSuccessNotification('Salvataggio effettuato con successo!'))
    return { element: response.data }
  } catch (err) {
    console.log(err)
    thunkApi.dispatch(addErrorNotification('Impossibile effettuare il salvataggio!'))
    return thunkApi.rejectWithValue({})
  }
})

export const deleteElement = createAsyncThunk('topics/delete_topic', async (element_id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/topics/${element_id}`,
      method: 'DELETE',
    })
    thunkApi.dispatch(addSuccessNotification('Elemento eliminato con successo'))
    return {}
  } catch (err) {
    thunkApi.dispatch(addErrorNotification('Impossibile eliminare!'))
    return thunkApi.rejectWithValue()
  }
})

export const topicsSlice = createSlice({
  name: 'topics',
  initialState,

  reducers: {
    setLoading: state => {
      state.loading = true
    },
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    toggleDisabledFilter: state => {
      state.show_disabled = !state.show_disabled
    },
    resetCurrentElement: state => {
      state.current_element = {}
    },
    updateErrors: (state, action) => {
      state.errors = action.payload.errors
    },
    setCurrentElement: (state, action) => {
      state.current_element = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getElements.fulfilled, (state, action) => {
        let { elements, total } = action.payload
        state.elements = elements
        state.total = total
        state.loading = false
      })
      .addCase(getElements.rejected, state => {
        state.elements = []
        state.total = 0
        state.loading = false
      })
      .addCase(updateElement.rejected, () => {
        throw new Error()
      })
      .addCase(createElement.rejected, () => {
        throw new Error()
      })
  },
})

export const {
  setLoading,
  toggleDisabledFilter,
  resetCurrentElement,
  toggleShowForm,
  updateErrors,
  setCurrentElement,
} = topicsSlice.actions
export default topicsSlice.reducer

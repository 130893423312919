import i18next from 'i18next'
import isEmail from 'validator/lib/isEmail'

const { t } = i18next

const validateFields = data => {
  let requiredFields = ['first_name', 'last_name', 'username', 'role', 'email']

  let errors = {}

  // check required fields
  for (const field of requiredFields) {
    if (!data[field]) errors[field] = t('user.user_form.errors.required_field', 'Campo richiesto')
  }

  if (data.email && !isEmail(data.email)) {
    errors.email = errors.email ? errors.email : t('user.user_form.errors.invalid_email', 'Formato email non valido')
  }
  if (data.role === '') {
    errors.role = errors.role_id ? errors.role_id : t('user.user_form.errors.invalid_role_id', 'Ruolo richiesto')
  }

  if (data.password && data.password !== data.confirm_password) {
    errors.password = errors.password
      ? errors.password
      : t('user.user_form.errors.invalid_password', 'Password differenti')
    errors.confirm_password = errors.confirm_password
      ? errors.confirm_password
      : t('user.user_form.errors.invalid_password', 'Password differenti')
  }
  return errors
}

export default validateFields
